import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore ( {
	id: 'notificationsStore',
	state: () => ( {
		notifications: [] as Notification[]
	} ),
	actions: {
		addNotification ( title: string, message: string, type: 'info' | 'success' | 'warning' | 'error' = 'info' )
		{
			const id = crypto.randomUUID ()
			this.notifications.push ( {
				id,
				title,
				message,
				createdAt: new Date (),
				type
			} as Notification )

			return id
		},
		removeNotification ( id: string )
		{
			this.notifications = this.notifications.filter ( n => n.id !== id )
		}
	},
} )
